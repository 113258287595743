import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, feadIn, HERO_BANNER_1, toAbsoluteUrl } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import RamerMotion from 'src/shadcn/components/custom/Animations/RamerMotion'
import { Button, CarouselItem } from 'src/shadcn/components/ui'
import { motion, useInView, useScroll } from "framer-motion";
import { AnimatedText } from 'src/shadcn/components/custom/Animations/AnimatedText'

type Props = {}

const Banner1 = (props: Props) => {

  const navigate = useNavigate()

  return (
    <CarouselItem >
      <div
        className={cn(`w-full h-[650px] `)}
        style={{
          background: `url(${HERO_BANNER_1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className=" w-[500px] space-y-3 ml-40">
          <div className='pt-[15vh]'>
            <div className='w-[400px]'>
              <img src={toAbsoluteUrl("/media/svg/Electricity_PM.svg")} style={{ height: "100px" }} className='mb-4' />
              <div className='space-y-1'>
                {/* <motion.div className="card"
                  initial="hidden" whileInView="show" viewport={{ amount: 0.1 }} variants={feadIn("up", 0.1, 50)}  >
                  <div className="font-sans font-bold text-4xl uppercase ">
                    <RamerMotion>One Smart <span className='text-primary'>Decision</span>,</RamerMotion>

                    One Smart Decision,
                  </div>
                </motion.div > */}
                <div className="font-sans font-bold text-4xl uppercase ">
                  {/* <RamerMotion>One Smart <span className='text-primary'>Decision</span>,</RamerMotion> */}

                  One Smart Decision,
                </div>
                {/* <motion.div className="card"
                  initial="hidden" whileInView="show" viewport={{ amount: 0.1 }} variants={feadIn("up", 0.1, 50)}  >
                  <div className="font-sans font-bold text-4xl uppercase ">Double The Impact</div>
                </motion.div > */}
                <div className="font-sans font-bold text-4xl uppercase ">Twice The Impact</div>


              </div>
              <div className='h-[2px] bg-primary w-[390px] my-4'></div>
            </div>
            {/* <div className='my-8'>Opt for renewable energy to reduce your electricity bill <br /> while building a sustainable future</div> */}
            <div className='my-5'>
              {/* <AnimatedText
                el="div"
                text={[
                  "Opt for renewable energy to reduce your electricity bill ",
                  "while building a sustainable future.",
                ]}
                className=""
                repeatDelay={0}
              /> */}
              <div className='my-5'>Opt for renewable energy to reduce your electricity bill <br /> while building a sustainable and a greener future</div>
            </div>
            <div >
              <div className='font-bold'>Your partner for:</div>
              <ul className='list-disc ml-4'>
                <li>Choosing financing options at competitive rates.</li>
                <li>Access to high-quality materials with warranties.</li>
                <li>Ensuring excellent workmanship with trusted partners.</li>
                {/* <li>Benefit from 20 years of performance tracking and record management</li> */}
              </ul>
            </div>
          </div>
          <div className='flex space-x-2'>
            <Button onClick={() => {
              const violation: any = document.getElementById("welcome-services-commercial-section");
              window.scrollTo({
                top: violation.offsetTop - 50,
                behavior: "smooth"
              });
            }} className='w-[150px]'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner1