import FilterMain from 'src/modules/Marketplace/Filters/FilterMain'
import React, { useEffect, useState } from 'react'
import ResultFilter from 'src/modules/Marketplace/Result/ResultFilter'
import { Separator } from 'src/shadcn/components/ui/separator'
import { catchAsync, stringifyObjectToRequestQuery, toAbsoluteUrl } from 'src/helpers'
import { getAllProjectsRequestApi } from 'src/services/requests/Projects'
import { AxiosResponse } from 'axios'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'
import ProjectResultsLoader from 'src/modules/Marketplace/Loaders/ProjectResultsLoader'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

type Props = {}

const MarketplaceMain = (props: Props) => {



  const [projects, setprojects] = useState([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    handleGetAllProjects()
    return () => { }
  }, [])


  const handleGetAllProjects = catchAsync(async (query?: string) => {
    setisLoading(true)
    return await getAllProjectsRequestApi(query)
  }, (results: AxiosResponse) => {
    setprojects(results.data)
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  const handleFilters = (e: any) => {
    delete e["price"]
    console.log({ filter: e });
    let prepareQueryString = stringifyObjectToRequestQuery(e)
    handleGetAllProjects(prepareQueryString)
  }


  return (
    <div className=''>
      <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + toAbsoluteUrl("/media/jpg/marketExplorerBanner.png") + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center items-center h-[100%] space-y-2'>
          <div className='text-white font-bold text-3xl ' >
            <div>Discover, Compare, and</div>
            <div>Choose Solar Project Experience</div>
          </div>
          <div className='text-white text-center ' >Explore a wide range of solar projects <br /> tailored to meet your solar projects needs.</div>
        </div>
      </div>
      <div className='flex flex-col items-center py-16 space-y-4'>
        <div className='text-center  text-3xl '>
          Find <span className='font-bold'>Solar Projects </span> of your choice on
        </div>
        <img src={toAbsoluteUrl("/media/svg/Electricity_PM.svg")} style={{ height: "80px" }} className='mb-4' />
      </div>
      <div className='w-[90vw] mx-auto grid grid-cols-4 gap-6'>
        <div className='p-2'>
          <FilterMain onChange={handleFilters} />
        </div>
        <div className='col-span-3 p-2 space-y-2 '>
          <div className='flex justify-between items-center'>
            <div className='uppercase text-primary font-bold'>Projects</div>
            <div className='flex space-x-2 text-xs'>Total : <span></span>{projects.length}<span></span></div>
          </div>
          {
            isLoading ? <div className='flex flex-col space-y-2'>
              {
                [...new Array(3)].map((_) => {
                  return <ProjectResultsLoader />
                })
              }
            </div> : <ResultFilter projects={projects} loading={isLoading} />
          }
        </div>

      </div>
    </div>
  )
}

export default MarketplaceMain