import React from 'react'
import { SECTION_BG, toAbsoluteUrl } from 'src/helpers'
import P2pDownloadApp from 'src/modules/P2pEnergyTrading/P2pDownloadApp'
import P2pEnergyHowItWorks from 'src/modules/P2pEnergyTrading/P2pEnergyHowItWorks'
import P2pEnergyJoiningSection from 'src/modules/P2pEnergyTrading/P2pEnergyJoiningSection'
import P2pEnergyOurProducts from 'src/modules/P2pEnergyTrading/P2pEnergyOurProducts'
import P2pEnergyTradingBanner from 'src/modules/P2pEnergyTrading/P2pEnergyTradingBanner'
import P2pEnquiryForm from 'src/modules/P2pEnergyTrading/P2pEnquiryForm'
import P2penrgyBenefits from 'src/modules/P2pEnergyTrading/P2penrgyBenefits'
import P2pFaq from 'src/modules/P2pEnergyTrading/P2pFaq'

type Props = {}

const P2pEnergyLandingPage = (props: Props) => {
  return (
    <div  >
      <div className='p-8 h-[500px] border-b-2  ' style={{ backgroundImage: "url(" + SECTION_BG + ")", backgroundSize: "cover" }} >
        <div className='grid grid-cols-4 max-w-[80vw] mx-auto'  >
          <div className='space-y-4 col-span-3'>
            {/* <div><span className='p-2 rounded-full bg-white'>NPCL Presents <span className='font-bold'>Solar mitra</span></span></div> */}
            <div className='text-2xl '>A <span className='font-bold'>Peer to Peer Energy Trading</span> Platform</div>
            <img className='mt-4' src={toAbsoluteUrl("/media/jpg/P2PBanner.webp")} style={{ height: "300px" }} />
          </div>
          <div className='space-y-2' >
            <div className='flex  items-center justify-center mb-8 '>
              <img src={toAbsoluteUrl("/media/svg/Electricity_EM.svg")} className='' style={{ height: "100px" }} />
              {/* <img src={toAbsoluteUrl("/media/png/npcl_logo.png")} className='' style={{ height: "30px" }} /> */}
            </div>
            <div className='bg-white p-4 border-2 border-primary '>
              <div className='text-lg  font-bold uppercase text-primary text-center'>Let's Explore Now</div>
              <div>
                <P2pEnquiryForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <P2penrgyBenefits />
      <P2pEnergyOurProducts />
      <P2pEnergyHowItWorks />
      {/* <P2pEnergyJoiningSection /> */}
      <P2pDownloadApp />
      {/* <P2pFaq /> */}
    </div>
  )
}

export default P2pEnergyLandingPage