import React, { ChangeEvent, useEffect, useState } from 'react'
import ProjectTypeFilter from './ProjectTypeFilter'
import { Button, Checkbox, Input, Label, Separator } from 'src/shadcn/components/ui'
import { BUILDING_TYPES, convertToCamelCase, convertToNumber, NAME_REGEX, optionTypes, parseFiltersDataIntoQuery, PROJECT_TYPES, STATES_OPTIONS, SYSTEM_TYPES } from 'src/helpers'
import FilterFormComponent from 'src/modules/FilterModule/FilterFormComponent'
import { useLocation, useParams } from 'react-router-dom'

type Props = {
    onChange: (e: any) => void
}


const PROJECT_TYPE_FILTERS = ["Project rights", "Turnkey", "In operation", "transferable system", "Unused area"]
const SYSTEM_TYPE_FILTERS = ["Ground mounted", "Rooftop mounted"]

const FILTERS = [
    {
        name: 'Commercial & Industrial',
        dataKey: "commercialAndIndustrial",
        type: 'list',
        optionsType: "multiselect",
        options: ['Buy Project Rights', 'Buy Existing Projects', 'Buy New Construction Projects', 'Lease Roof Areas', 'Offer Land For Utility Scale Project'].map((d: string) => ({ label: d, value: d }))
    },
    {
        name: 'Residential & Societies',
        dataKey: "residentialAndSocieties",
        type: 'list',
        optionsType: "multiselect",
        options: ['Competitive finance', 'Solar EPC', 'Rooftop Solar'].map((d: string) => ({ label: d, value: d }))
    },
    {
        name: 'Project Type',
        dataKey: "projectType",
        type: 'list',
        optionsType: "multiselect",
        options: PROJECT_TYPES
    },
    {
        name: 'State',
        dataKey: "state",
        type: 'dropdown',
        options: STATES_OPTIONS
        // optionsType: "singleselect",
        // options: []
    },
    {
        name: 'Project ID',
        dataKey: "projectId",
        type: 'text',
    },
    // {
    //     dataKey: "projectName",
    //     name: 'Project Name',
    //     type: 'text',
    //     regex: NAME_REGEX
    // },
    {
        name: 'Investments',
        dataKey: "investments",
        type: 'range',
        min: 1000,
        max: 1000000000,
    },

];


const RESET_FILTER = {
    "commercialAndIndustrial": [],
    "residentialAndSocieties": [],
    "projectType": [],
    "investments": {
        min: 0,
        max: 0
    },
    "state": "",
    "project_id": "",
    "projectName": ""
}


const FilterMain = ({ onChange }: Props) => {



    const { search } = useLocation()

    const [selectedFilters, setselectedFilters] = useState<{
        "commercialAndIndustrial": string[],
        "residentialAndSocieties": string[],
        "projectType": string[],
        "investments": {
            min: number,
            max: number
        },
        "state": string,
        "project_id": string,
        "projectName": string
    }>(RESET_FILTER)

    console.log({ search });

    useEffect(() => {
        if (search) {
            let filterData = search ? search.replace("?", "").split("&").reduce((acc: any, curr: any) => {
                const [key, value] = curr.split("=");
                if (["commercialAndIndustrial", "residentialAndSocieties", "projectType"].includes(key) && value) {
                    acc[key as keyof object] = value ? value.split(",").map((d: any) => decodeURI(d)) : [];
                }
                else if (["investments"].includes(key) && value) {
                    let [min, max] = value.split(":")
                    acc[key as keyof object] = value ? {
                        min: min,
                        max: max
                    } : {
                        min: 0,
                        max: 0
                    };
                }
                else acc[key as keyof object] = value ? decodeURI(value) : "";
                return acc;
            }, {}) : {};
            console.log({ filterData });
            setselectedFilters({ ...RESET_FILTER, ...filterData })
            onChange(parseFiltersDataIntoQuery(filterData))
        }
        return () => { }
    }, [search])


    console.log({ selectedFilters });
    return (
        <FilterFormComponent filterData={FILTERS} selectedFilters={selectedFilters} onChange={(e: any) => { onChange(e) }} />
    )
}

export default FilterMain