import { FormField, NAME_REGEX, toAbsoluteUrl } from 'src/helpers';
import * as Yup from 'yup';

export interface IPersonalDetails {
    yourName: string | undefined,
    yourMobileNo: string | undefined,
}

export interface IFriendDetails {
    friendName: string | undefined,
    friendMobileNo: string | undefined,
}


export const PersonalDetails: IPersonalDetails = {
    yourName: "",
    yourMobileNo: ""
}

export const FriendDetails: IFriendDetails = {
    friendName: "",
    friendMobileNo: ""
}

export const FORM_SEQUENCE = {
    PersonalDetails: "Personal Details",
    FriendDetails: "Friend Details"
}

export const REFERRAL_FORM_INIT = {
    ...PersonalDetails,
    ...FriendDetails
}

export type IREFERRAL_FORM = IPersonalDetails & IFriendDetails


export const REFERRAL_FORM_FIELDS: FormField[] = [
    {
        label: 'Your Name',
        dataKey: 'yourName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Name',
        },
    },
    {
        label: 'Your Mobile No',
        dataKey: 'yourMobileNo',
        componentType: 'input',
        componentProps: {
            type: "number",
            maxLength: 10,
            placeholder: 'Ex:91XXXXXXXX',
        },
    },
    {
        label: "Friend's Name",
        dataKey: 'friendName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Name',
        },
    },
    {
        label: "Friend's Mobile No",
        dataKey: 'friendMobileNo',
        componentType: 'input',
        componentProps: {
            type: "number",
            maxLength: 10,
            placeholder: 'Ex:91XXXXXXXX',
        },
    },
]


export const REFERRAL_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    yourName: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters long'),
    yourMobileNo: Yup.string()
        .required('Mobile number is required')
        .length(10, 'Friend mobile number must be exactly 10 digits'),
    friendName: Yup.string()
        .required('Friend name is required')
        .min(2, 'Friend name must be at least 2 characters long'),
    friendMobileNo: Yup.string()
        .required('Friend mobile number is required')
        .length(10, 'Friend mobile number must be exactly 10 digits'),
});



export const REWARD_BASE_PRICES_DATA = [
    {
        "size": "1-3 kWh",
        "reward": "₹ 1500"
    },
    {
        "size": "4-6 kWh",
        "reward": "₹ 4000"
    },
    {
        "size": "7-10 kWh",
        "reward": "₹ 6000"
    },
    {
        "size": "11-15 kWh",
        "reward": "₹ 8000"
    },
    {
        "size": "16-20 kWh",
        "reward": "₹ 12000"
    },
    {
        "size": "20 kWh & above",
        "reward": "₹ 16000"
    }
]

export const HOW_IT_WORKS = [
    {
        "header": "Step 1",
        "image": toAbsoluteUrl("/media/png/re_how_it_works_1.png"),
        "title":"Share your unique referral link",
        "description": "Invite friends, family, or anyone you know to install solar project by sharing your personal referral link."
    },
    {
        "header": "Step 2",
        "image": toAbsoluteUrl("/media/png/re_how_it_works_2.png"),
        "title":"They choose solar",
        "description": "Once they decide to go solar with us and complete the installation, you'll earn rewards!"
    },
    {
        "header": "Step 3",
        "image": toAbsoluteUrl("/media/png/re_how_it_works_3.png"),
        "title":"You earn rewards",
        "description": "Get rewarded for every successful referral. The more people you refer, the more you can earn!"
    }
]

export const AMBASSADORS = [
    {
        "header": "Exclusive Rewards",
        "image": toAbsoluteUrl("/media/png/Ambassdor1.png"),
        "description": "Special bonuses and gifts for top referrers."
    },
    {
        "header": "Recognition",
        "image": toAbsoluteUrl("/media/png/Ambassdor2.png"),
        "description": "Be highlighted on our website as a Referral Ambassador."
    },
    {
        "header": "Impact",
        "image": toAbsoluteUrl("/media/png/Ambassdor3.png"),
        "description": "Make an impact by assisting others in transitioning to solar energy."
    }
]

export const TESTIMONIALS = [
    {
        "image": toAbsoluteUrl("/media/png/avatar1.jpg"),
        "description": "I referred my neighbors and friends, and not only did they save on their electricity bills, but I earned enough to cover my own installation cost! It feels great to help others while making a positive impact on the environment.",
        "name": "RAMESH GUPTA",
        "city": "NOIDA",
        "state": "INDIA",
        "total_referals": 10,
        "rating": 5
    },
    {
        "image": toAbsoluteUrl("/media/png/avatar2.jpg"),
        "description": "I referred my neighbors and friends, and not only did they save on their electricity bills, but I earned enough to cover my own installation cost! It feels great to help others while making a positive impact on the environment.",
        "name": "CHRISTINE DSOUZA",
        "city": "NOIDA",
        "state": "INDIA",
        "total_referals": 15,
        "rating": 5
    },
    {
        "image": toAbsoluteUrl("/media/png/avatar3.jpg"),
        "description": "I referred my neighbors and friends, and not only did they save on their electricity bills, but I earned enough to cover my own installation cost! It feels great to help others while making a positive impact on the environment.",
        "name": "RAMESH GUPTA",
        "city": "NOIDA",
        "state": "INDIA",
        "total_referals": 10,
        "rating": 2
    }
]