import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { toAbsoluteUrl as IMG_FAQ_EQUATION_MARK, toAbsoluteUrl } from "../AssetHelpers";
import MainRoutes from "src/navigations";
import { ClassNameValue } from "tailwind-merge";


export const ERROR_CODES = [400, 500, 404];

export type optionTypes = {
  label: string,
  value: string,
  icons?: any
}

export type componentType = 'input' | 'inputicon' | 'options' | 'date_picker' | 'file_picker' | "textarea";
export type componentProps = {
  type?: 'email' | 'password' | 'phone' | 'text' | 'url' | 'number',
  className?: string,
  placeholder?: string
  regex?: RegExp,
  maxLength?: number,
  uppercase?: boolean,
  options?: optionTypes[],
  inputSuffixIcon?: any,
  inputPrefixIcon?: any,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  showCharacterLength?: boolean,
  fileSelectionType?: "single" | "multi",
  numberFormatted?: boolean,
  labelStyle?: ClassNameValue,
  description?: string
  numberPattern?: string
  conditionalDisabled?: {
    formKey: string,
    expectedValue: string
  },
  inputWidth?: number,
  hide?: {
    formKey: string,
    expectedValue: string
  },
  conditionalHide?: {
    formKey: string,
    expectedValue: string | string[]
  } & Record<string, any>;
  error_className?: ClassNameValue
}

export interface FormField {
  label: string;
  dataKey: string;
  value?: string,
  componentType: componentType,
  componentProps?: componentProps
}


export const NAV_TABS = {
  home: MAIN_ROUTES_CONST.welcome,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  solarKit: MAIN_ROUTES_CONST.solarkit,
  contact_us: MAIN_ROUTES_CONST.contact_us
}

export const APP_NAV_TABS = {
  dashboard: MAIN_ROUTES_CONST.dashboard,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  investment_calculator: MAIN_ROUTES_CONST.investment_calculator,
  solarkit: MAIN_ROUTES_CONST.solarkit,
  enquiry_request: MAIN_ROUTES_CONST.new_enquiry_request,
  contact_us: MAIN_ROUTES_CONST.contact_us,
}

export const HERO_IMG_1 = IMG_FAQ_EQUATION_MARK("/media/png/hero-img-1.png")
export const IMG_FEATURE_P2P_ENERGY_TRADING = IMG_FAQ_EQUATION_MARK("/media/png/featutre_p2p_energy_trading.png")
export const IMG_FEATURE_P2P_CROWDFUNDING = IMG_FAQ_EQUATION_MARK("/media/png/featutre_p2p_crowed_funding.png")
export const IMG_FEATURE_P2P_MARKETPLACE = IMG_FAQ_EQUATION_MARK("/media/png/featutre_p2p_market_place.png")
export const IMG_DISCOVERS_BG = IMG_FAQ_EQUATION_MARK("/media/png/discover_bg.png")
export const IMG_FEATURE_INFO_1 = IMG_FAQ_EQUATION_MARK("/media/png/info1.png")
export const SVG_FEATURE_INFO_1 = IMG_FAQ_EQUATION_MARK("/media/svg/image/info1.svg")
export const IMG_FEATURE_INFO_2 = IMG_FAQ_EQUATION_MARK("/media/png/info2.png")
export const IMG_FEATURE_INFO_3 = IMG_FAQ_EQUATION_MARK("/media/png/info3.png")
export const IMG_FEATURE_INFO_4 = IMG_FAQ_EQUATION_MARK("/media/png/info4.png")
export const IMG_FEATURE_INFO_5 = IMG_FAQ_EQUATION_MARK("/media/png/info5.png")
export const ICON_ENERGY_SUN = IMG_FAQ_EQUATION_MARK("/media/svg/icons/energy_sun.svg")
export const ICON_GREEN_ENERGY = IMG_FAQ_EQUATION_MARK("/media/svg/icons/green_energy.svg")
export const ICON_BASKET = IMG_FAQ_EQUATION_MARK("/media/svg/icons/basket.svg")
export const ICON_PAYMENT_RECEIVE = IMG_FAQ_EQUATION_MARK("/media/svg/icons/payment_receive.svg")
export const ICON_EMAIL = IMG_FAQ_EQUATION_MARK("/media/svg/icons/email.svg")
export const IMG_FAQ_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/faq_banner.png")
export const IMG_ABOUT_US_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/about_us_banner.jpg")
export const IMG_CONTACT_US_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/contact_us_banner.jpg")
export const IMG_CROWDFUNDING_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/croudfunding_banner.png")
export const IMG_GREEN_ENERGY_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/green_energy_banner.png")
export const IMG_CROWDFUNDING_SELL_ENERGY = IMG_FAQ_EQUATION_MARK("/media/png/sellEnergy.png")
export const IMG_CROWDFUNDING_CROWD_FUNDING = IMG_FAQ_EQUATION_MARK("/media/png/crowd-funding.png")
export const IMG_CROWDFUNDING_BUY_ENERGY = IMG_FAQ_EQUATION_MARK("/media/png/BuyersFundig.png")
export const IMG_GREEN_ENERGY_AGGREMENT = IMG_FAQ_EQUATION_MARK("/media/png/Agreement.png")
export const IMG_P2P_ENERGY_TRADING = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_trading.jpg")
export const IMG_GREEN_ENERGY_TOKENIZED = IMG_FAQ_EQUATION_MARK("/media/png/TokenizedSolar.png")
export const IMG_GREEN_ENERGY_VIRTUAL_ROOFTOP = IMG_FAQ_EQUATION_MARK("/media/png/Virtual_Rooftop.png")
export const ICON_CURLY = IMG_FAQ_EQUATION_MARK("/media/png/curly.png")
export const ICON_PROJECT_SIZE = IMG_FAQ_EQUATION_MARK("/media/png/project_size.png")
export const ICON_PROJECT_TYPE = IMG_FAQ_EQUATION_MARK("/media/png/project_type.png")
export const ICON_ROOF_TOP = IMG_FAQ_EQUATION_MARK("/media/png/roof-top.png")
export const ICON_SEND = IMG_FAQ_EQUATION_MARK("/media/png/send.png")
export const ICON_RECEIVE = IMG_FAQ_EQUATION_MARK("/media/png/receive.png")
export const ICON_PROFIT_AS_BUYER = IMG_FAQ_EQUATION_MARK("/media/png/profitAsBuyerIcon.png")
export const ICON_PROFIT_AS_SALER = IMG_FAQ_EQUATION_MARK("/media/png/profitAsSallerIcon.png")
export const ICON_WALLET = IMG_FAQ_EQUATION_MARK("/media/png/Wallet.png")
export const ICON_OCT_VERIFIED = IMG_FAQ_EQUATION_MARK("/media/png/oct-verified.png")
export const IMG_EPC_COMPANY_PLACEHOLDER_LOGO = IMG_FAQ_EQUATION_MARK("/media/png/company-logo.png")
export const IMG_EPC_COMPANY_LOGO = IMG_FAQ_EQUATION_MARK("/media/png/Nous.png")
export const IMG_SOLAR_ROOF_TOP = IMG_FAQ_EQUATION_MARK("/media/png/solar-rooftops.png")
export const IMG_SOLAR_FARMS = IMG_FAQ_EQUATION_MARK("/media/png/solar-farms.png")
export const IMG_SOLAR_WATER_PUMP = IMG_FAQ_EQUATION_MARK("/media/png/Solar-Water-Pumps.png")
export const IMG_SAMPLE_PANEL_1 = IMG_FAQ_EQUATION_MARK("/media/png/sample_panel_1.png")
export const IMG_SAMPLE_PANEL_2 = IMG_FAQ_EQUATION_MARK("/media/png/sample_panel_2.png")
export const IMG_SERVICES_BANNER = IMG_FAQ_EQUATION_MARK("media/svg/image/ServiceSolarImg.svg")
export const IMG_ROUNDED_1 = IMG_FAQ_EQUATION_MARK("media/png/rounded_1.png")
export const IMG_ROUNDED_2 = IMG_FAQ_EQUATION_MARK("media/png/rounded_2.png")
export const IMG_ROUNDED_3 = IMG_FAQ_EQUATION_MARK("media/png/rounded_3.png")
export const IMG_LAND_LEASING_BENEFITS = IMG_FAQ_EQUATION_MARK("media/jpg/Leasing_Land_Benifits.png")
export const IMG_WELCOME_SERVICES_BANNER_BG = IMG_FAQ_EQUATION_MARK("/media/jpg/WelcomeServicesBg.png")
export const IMG_QUOTE_IMG = IMG_FAQ_EQUATION_MARK("/media/jpg/quote_img.jpg")
export const IMG_BLOCKCHAIN_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/BlackchainServiceBanner.png")
export const IMG_FAQ = IMG_FAQ_EQUATION_MARK("/media/svg/faq.svg")
export const IMG_ProductsBanner = IMG_FAQ_EQUATION_MARK("/media/jpg/ProductsBanner.png")
export const IMG_BLOCKCHAIN_IMG = IMG_FAQ_EQUATION_MARK("/media/jpg/blackchain_img.webp")
export const IMG_P2P_ENERGY_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_banner.png")
export const IMG_P2P_ENERGY_BENEFITS_BG = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_benifits_bg.png")
export const IMG_P2P_ENERGY_SCREEN_1 = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_screen_1.png")
export const IMG_P2P_ENERGY_HOW_IT_WORKS = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_how_it_works.webp")
export const IMG_P2P_ENERGY_OUR_PRODUCTS = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_enenrgy_our_products.png")
export const IMG_P2P_ENERGY_JOINING_TEXTURE = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_joining.png")
export const IMG_EMPTY_ROUND = IMG_FAQ_EQUATION_MARK("/media/png/empty_round.png")
export const IMG_P2P_DOWNLOAD_APP_BG_BANNER = IMG_FAQ_EQUATION_MARK("/media/jpg/p2p_energy_app_bg_banner.png")
export const IMG_P2P_DOWNLOAD_APP = IMG_FAQ_EQUATION_MARK("/media/png/p2p_energy_app.png")
export const IMG_TREE_ICON = IMG_FAQ_EQUATION_MARK("/media/png/tree_icon.png")
export const IMG_GOOGLE_PLAY = IMG_FAQ_EQUATION_MARK("/media/png/googleplay.png")
export const IMG_SERVICES_BANNER_2 = IMG_FAQ_EQUATION_MARK("/media/jpg/services_banner.png")
export const HERO_BANNER_1 = toAbsoluteUrl("/media/jpg/Banner1.webp");
export const WELCOME_SERVICE_BG = toAbsoluteUrl("/media/jpg/welcome_service_bg.webp");
export const IMG_COMMERCIAL_SERVICES_REFERENCES_BG = toAbsoluteUrl("/media/jpg/CommercialServiceReferencesBg.png");

export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_ROOFTOP_AREA = toAbsoluteUrl("/media/jpg/CommercialServices/Referances/RooftopArea.png");
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_TURNKEY_OPEN_FIELD = toAbsoluteUrl("/media/jpg/CommercialServices/Referances/TurnkeyOpenField.png");
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1 = toAbsoluteUrl("/media/jpg/CommercialServices/Referances/ProjectRights1.png");
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS2 = toAbsoluteUrl("/media/jpg/CommercialServices/Referances/ProjectRights2.png");

export const SECTION_BG = toAbsoluteUrl("/media/jpg/SectionBG.webp");
export const HERO_BANNER_2 = toAbsoluteUrl("/media/jpg/Banner2.webp");
export const HERO_BANNER_3 = toAbsoluteUrl("/media/jpg/Banner3.webp");
export const HERO_BANNER_4 = toAbsoluteUrl("/media/jpg/Banner4.png");
export const HERO_BANNER_5 = toAbsoluteUrl("/media/jpg/Banner5.png");
// export const HERO_BANNER_4 =  toAbsoluteUrl("/media/jpg/hero_banner_4.png");
// export const HERO_BANNER_5 =  toAbsoluteUrl("/media/jpg/hero_banxner_5.jpg");
// export const HERO_BANNER_3 =  toAbsoluteUrl("/media/jpg/hero_banner_3.png");
// export const HERO_BANNER_2_2 =  toAbsoluteUrl("/media/jpg/hero_banner_2_2.png");
export const HAND_SHAK_IMG = toAbsoluteUrl("/media/jpg/hand_shak_img.webp");
export const ANNOTATION_ICON = toAbsoluteUrl("/media/png/anotation_icon.png");

export const PROJECT_IMAGE_URL = "https://images.unsplash.com/photo-1497440001374-f26997328c1b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

export const successTheme = {
  background: '#fff',
  color: "hsl(var(--primary))",
  borderRadius: '8px',
  padding: '16px',
};

export const errorTheme = {
  background: '#FF5722',
  color: '#FFF',
  borderRadius: '8px',
  padding: '16px',
};


export const BG_SKIN_COLOR_LIGHT = "bg-[#E8E3E3]"


export const USER_ROLES = {
  "INVESTOR": "investor",
  "OFF_TAKER": "offtakers"
}


export const NUMBER_REGEX = /[^0-9.]+/g
export const PHONE_NUMBER_REGEX = /[^0-9]+/g
export const EMAIL_REGEX = /[^a-z0-9.@]+/g
export const NAME_REGEX = /[^A-Za-z ]+/g;
export const EXTRACT_NUMBERS_REGEX = /[^A-z0-9.]+/g
export const LAT_LONG_REGEX = /[^0-9., ]+/g
export const ADDRESS_REGEX = /[^A-z0-9/-_, ]+/g
export const EXTRACT_STRING_REGEX = /[^A-Za-z ]+/g;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/


export const SESSIONS = {
  FORGOT_PASSWORD: "FORGOT-PASSWORD",
  REGISTRATION: "REGISTRATION"
}

export const ORDER_STATUS_ENUM = {
  "PENDING": "PENDING",
  "SHIPPED": "SHIPPED",
  "DELIVERED": "DELIVERED",
  "CANCELLED": "CANCELLED",
}

export const BADGE_STATUS_VARIANT = {
  "PENDING": "warning",
  "SHIPPED": "warning",
  "DELIVERED": "success",
  "CANCELLED": "destructive",
  "PAID": "success",
}

export const PAYMENT_STATUS_ENUM = {
  "PENDING": "PENDING",
  "PAID": "PAID",
  "CANCELLED": "CANCELLED",
}


export const PROJECT_CATEGORY_OPTIONS = [
  {
    "label": "Community Solar",
    "value": "Community Solar"
  },
  {
    "label": "Ground-Mounted",
    "value": "Ground-Mounted"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Solar Parks",
    "value": "Solar Parks"
  },
  {
    "label": "Rooftop Solar",
    "value": "Rooftop Solar"
  },
  {
    "label": "Carport Solar",
    "value": "Carport Solar"
  },
  {
    "label": "Hybrid (Solar + Wind +Battery)",
    "value": "Hybrid (Solar + Wind +Battery)"
  },
  {
    "label": "BESS (Energy Storage System)",
    "value": "BESS (Energy Storage System)"
  }
]

export const PROJECT_TYPES = [
  // { label: 'Matchmaking', value: 'Matchmaking' },
  { label: 'GROUND MOUNTED', value: 'GROUND MOUNTED' },
  { label: 'ROOF-TOP', value: 'ROOF TOP' },
  // { label: 'EaaS', value: 'EaaS' },
  // { label: 'VPP', value: 'VPP' },
];

export const PROPERTY_TYPE_OPTIONS = [{
  "label": "Rented",
  "value": "Rented"
}, {
  "label": "Ownership",
  "value": "Ownership"
}];

export const BUILDING_TYPES = [{
  "label": "Residential",
  "value": "Residential"
}, {
  "label": "Commercial",
  "value": "Commercial"
}, {
  "label": "Industrial",
  "value": "Industrial"
}];



export const AUTHORIZED_PARTNER_OPTIONS = [{
  "label": "Gold",
  "value": "Gold"
}, {
  "label": "Platinum",
  "value": "Platinum"
}, {
  "label": "Silver",
  "value": "Silver"
}];



export const VERIFIED_UNVERIFIED_OPTIONS = [{
  "label": "Verified",
  "value": "Verified"
}, {
  "label": "Unverified",
  "value": "Unverified"
}];



export const SYSTEM_TYPES = [
  {
    "label": "Photovoltaic(PV)",
    "value": "Photovoltaic"
  },
  {
    "label": "Solar Thermal",
    "value": "Solar Thermal"
  },
  {
    "label": "Off-Grid Solar",
    "value": "Off-Grid Solar"
  },
  {
    "label": "On-Grid Solar",
    "value": "On-Grid Solar"
  },
  {
    "label": "Hybrid Systems",
    "value": "Hybrid Systems"
  },
  {
    "label": "Concentrated Solar Power (CSP)",
    "value": "Concentrated Solar Power (CSP)"
  },
  {
    "label": "Building-Integrated Photovoltaic (BIPV)",
    "value": "Building-Integrated Photovoltaic (BIPV)"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Agrivoltaics (Agro-Solar)",
    "value": "Agrivoltaics (Agro-Solar)"
  }
]

export const PROJECT_LAND_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Agricultural', value: 'Agricultural' },
  { label: 'Institutional', value: 'Institutional' },
  { label: 'Government', value: 'Government' },
];

export const SERVICE_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Agricultural', value: 'Agricultural' },
  { label: 'Institutional', value: 'Institutional' },
  { label: 'Government', value: 'Government' },
];


export const DUMMY_OPTIONS = [
  {
    label: 'Type 1',
    value: 'type1',
  },
  {
    label: 'Type 2',
    value: 'type2',
  },
]


export const indianNumberFormatter = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'INR'
});

export const AVAILABLE_SPACE_UNITS_OPTIONS = [
  { label: "SqFt", value: "SqFt" },
  { label: "meter", value: "m" },
  { label: "feet", value: "ft" },
  { label: "SqM", value: "SqM" },
]

export const PROPERTY_TYPE_ICONS = {
  "BOOT": IMG_FAQ_EQUATION_MARK("/media/png/boot.png"),
  "GROUND MOUNTED": IMG_FAQ_EQUATION_MARK("/media/svg/8_Ground Mounted final.svg"),
  "ROOF TOP": IMG_FAQ_EQUATION_MARK("/media/png/9_Rooftop final.svg"),
}

// export const PROPERTY_TYPE_ICONS = {
//   "BOOT": IMG_FAQ_EQUATION_MARK("/media/png/boot.png"),
//   "GROUND MOUNTED": IMG_FAQ_EQUATION_MARK("/media/png/boot.png"),
// }


export const PROJECT_CATEGORY_ICON = {
  "Buy Project Rights": toAbsoluteUrl("/media/svg/Buy Project Rights Final.svg"),
  "Buy Existing Projects": toAbsoluteUrl("/media/svg/Buy Existing Projects final.svg"),
  "Buy New Construction Projects": toAbsoluteUrl("/media/svg/Buy New Construction Projects final.svg"),
  "Lease Roof Areas": toAbsoluteUrl("/media/svg/Lease Roof Areas Final.svg"),
  "Offer Land For Utility Scale Project": toAbsoluteUrl("/media/svg/Offer Land For Utility Scale Project Final.svg")
}


export const CUSTOMER_TYPE_ICON = {
  "Commercial & Industrial": toAbsoluteUrl("/media/svg/6_Commercial & Industrial final.svg"),
  "Residential & Society": toAbsoluteUrl("/media/svg/7_Residential & Societies final.svg")
};


export const BUILDING_TYPE_ICONS = {
  "Residential": IMG_FAQ_EQUATION_MARK("/media/png/property-residential.png"),
  "Commercial": IMG_FAQ_EQUATION_MARK("/media/png/property-commercial.png"),
  "Industrial": IMG_FAQ_EQUATION_MARK("/media/png/property-industry.png")
}


export const QUERY_TYPE_OPTIONS = [
  { label: "Account", value: "Account" },
  { label: "Project Information", value: "Project Information" },
  { label: "General Enquiry", value: "General Enquiry" },
  { label: "Transaction", value: "Transaction" },
  { label: "Investor", value: "Investor" },
  { label: "Offtaker", value: "Offtaker" }
]

export const DISCOMS_OPTIONS = [
  { label: "Account", value: "Account" },
  { label: "Project Information", value: "Project Information" },
  { label: "General Enquiry", value: "General Enquiry" },
  { label: "Transaction", value: "Transaction" },
  { label: "Investor", value: "Investor" },
  { label: "Offtaker", value: "Offtaker" }
]

export const EPC_CONTRACTORS_SPECIALIZATIONS = [
  "Solar farm design and engineering",
  "Procurement of solar panels, inverters, and other equipment",
  "Construction and installation of solar farms",
  "Routine maintenance and repair of solar farms",
  "Performance monitoring and optimization",
  "Troubleshooting and fault resolution",
  "Electrical infrastructure design and installation",
  "Electrical testing and commissioning",
  "Electrical maintenance and repair",
  "Site preparation and grading",
  "Foundation design and installation",
  "Road construction and maintenance",
  "Environmental impact assessments",
  "Permitting and compliance",
  "Wildlife habitat assessments and mitigation"
]

export const MANUFACTURERS_SPECIALIZATIONS = [
  "Monocrystalline silicon solar panels",
  "Polycrystalline silicon solar panels",
  "Thin-film solar panels",
  "Bifacial solar panels",
  "String inverters",
  "Central inverters",
  "Microinverters",
  "Power optimizers",
  "Fixed-tilt mounting systems",
  "Tracking mounting systems",
  "Roof-top mounting systems",
  "Ground-mounted systems",
  "Lithium-ion battery systems",
  "Lead-acid battery systems",
  "Flow battery systems",
  "Sodium-ion battery systems",
  "Data loggers",
  "Monitoring software",
  "Control systems for inverters and trackers",
  "Weather stations"
]

export const OTHER_SPECIALIZATIONS = [
  "Residential solar installations",
  "Commercial solar installations",
  "Utility-scale solar installations",
  "String inverter installation",
  "Central inverter installation",
  "Microinverter installation",
  "Electrical testing and inspection",
  "Commissioning of electrical systems",
  "Electrical safety auditing",
  "Preventive maintenance",
  "Corrective maintenance",
  "Predictive maintenance",
  "Energy audits and assessments",
  "Energy efficiency recommendations",
  "Energy management and monitoring"
]


export const ENUM_SERVICE_ENQUIRY_TYPE = {
  "landOwner": "landOwner",
  "roofTopSolar": "roofTopSolar",
  "groundMounted": "groundMounted",
  "projectRights": "projectRights",
  "newConstructedProjects": "newConstructedProjects",
  "sellExistingProject": "sellExistingProject",
  "finance": "finance",
  "solarEPC": "solarEPC",
  "solarOandM": "solarOandM"
}

export const ENQUIRY_SERVICE_TYPES: optionTypes[] = [
  {
    label: "Land Owner",
    value: "landOwner"
  },
  {
    label: "Rooftop Solar",
    value: "roofTopSolar"
  },
  {
    label: "Ground Mounted",
    value: "groundMounted"
  },
  {
    label: "Project Rights",
    value: "projectRights"
  },
  {
    label: "New Constructed Projects",
    value: "newConstructedProjects"
  },
  {
    label: "Sell Existing Project",
    value: "sellExistingProject"
  },
  {
    label: "Finance",
    value: "finance"
  },
  {
    label: "Solar EPC",
    value: "solarEPC"
  },
  {
    label: "Solar O&M",
    value: "solarOandM"
  }
];


export const SYSTEM_TYPE_OPTIONS = [
  {
    "label": "Photovoltaic(PV)",
    "value": "Photovoltaic(PV)"
  },
  {
    "label": "Solar Thermal",
    "value": "Solar Thermal"
  },
  {
    "label": "Off-Grid Solar",
    "value": "Off-Grid Solar"
  },
  {
    "label": "On-Grid Solar",
    "value": "On-Grid Solar"
  },
  {
    "label": "Hybrid Systems",
    "value": "Hybrid Systems"
  },
  {
    "label": "Concentrated Solar Power(CSP)",
    "value": "Concentrated Solar Power(CSP)"
  },
  {
    "label": "Building-Integrated Photovoltaic(BIPV)",
    "value": "Building-Integrated Photovoltaic(BIPV)"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Agrivoltaics (Agro-Solar)",
    "value": "Agrivoltaics (Agro-Solar)"
  }
]

export const YEARS_RANGE_OPTIONS = [
  {
    "label": "Less than 1 Years",
    "value": "Less than 1 Years"
  },
  {
    "label": "1-5 Years",
    "value": "1-5 Years"
  },
  {
    "label": "5-10 Years",
    "value": "5-10 Years"
  },
  {
    "label": "10-15 Years",
    "value": "10-15 Years"
  },
  {
    "label": "15-20 Years",
    "value": "15-20 Years"
  },
  {
    "label": "More than 20 Years",
    "value": "More than 20 Years"
  }
]

export const CONNECTION_TYPE_OPTIONS = [
  { label: "Single Phase", value: "Single Phase" },
  { label: "Three Phase (LV)", value: "Three Phase (LV)" },
  { label: "Three Phase (MV)", value: "Three Phase (MV)" }
]

export const TYPE_OF_SPACE_ENUM = {
  "Rooftop": "Rooftop",
  "Land": "Land"
}

export const TYPE_OF_SPACE_OPTIONS = [{
  "label": "Rooftop",
  "value": "Rooftop"
}, {
  "label": "Land",
  "value": "Land"
}]

export const ROOF_MATERIAL_OPTIONS = [
  {
    "label": "RCC",
    "value": "RCC"
  },
  {
    "label": "Metal Sheet",
    "value": "Metal Sheet"
  },
  {
    "label": "Coaler",
    "value": "Coaler"
  },
  {
    "label": "Other",
    "value": "Other"
  }
]

export const ROOF_ORIENTATION = [
  {
    label: "North",
    value: "North",
  },
  {
    label: "South",
    value: "South",
  },
  {
    label: "East",
    value: "East",
  },
  {
    label: "West",
    value: "West",
  },
];

export const ROOFTOP_MATERIAL_OPTIONS = [
  {
    label: "Concrete",
    value: "Concrete",
  },
  {
    label: "Tin",
    value: "Tin",
  },
  {
    label: "GI Sheet",
    value: "GI Sheet",
  },
  {
    label: "Clay Tiles",
    value: "Clay Tiles",
  },
  {
    label: "Fiber Sheets",
    value: "Fiber Sheets",
  },
  {
    label: "Other",
    value: "Other",
  },

]

export const SHADING_OPTIONS = [
  {
    label: "No Shading",
    value: "No Shading",
  },
  {
    label: "Partial Shading",
    value: "Partial Shading",
  },
  {
    label: "Full Shading",
    value: "Full Shading",
  },
];


export const ROOFTOP_PROPERTY_TYPE_OPTIONS = [
  {
    label: "Residential",
    value: "Residential",
  },
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Industrial",
    value: "Industrial",
  },
  {
    label: "Institutional",
    value: "Institutional",
  },];

export const ROOF_TYPE_OPTIONS = [
  {
    label: "Flat Roof",
    value: "Flat Roof"
  },
  {
    label: "Sloped Roof",
    value: "Sloped Roof"
  },
  {
    label: "Metal Roof",
    value: "Metal Roof"
  },
  {
    label: "RCC",
    value: "RCC"
  },
  {
    label: "Asbestos",
    value: "Asbestos"
  },
  {
    label: "Tiles",
    value: "Tiles"
  },
  {
    label: "Other",
    value: "Other"
  }
];

export const SOLAR_SYSTEM_TYPE_OPTIONS = [
  {
    label: "On-Grid",
    value: "On-Grid"
  },
  {
    label: "Off-Grid",
    value: "Off-Grid"
  },
  {
    label: "Hybrid",
    value: "Hybrid"
  }
];


export const DAY_TIME_UNITS_OPTIONS = [
  { label: "Days", value: "Days" },
  { label: "Years", value: "Years" },
  { label: "Months", value: "Months" },
]


export const PROJECT_OWNERSHIP_TYPE_OPTIONS = [
  { label: "Independent Power Producer", value: "Independent Power Producer" },
  { label: "Utility-Owned", value: "Utility-Owned" },
  { label: "Joint Venture", value: "Joint Venture" },
  { label: "Special Purpose Vehicle", value: "Special Purpose Vehicle" },
  { label: "Government-Owned", value: "Government-Owned" }
]

export const DC_COMMISIONING_TYPE_OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
]

export const TERM_PLAN_OPTIONS = [...Array(10)].map((_, indx: number) => {
  return { label: (indx + 1) + " Years", value: (indx + 1).toString() }
})


export const STATUS = {
  New: 'New',
  Approved: 'Approved',
  Active: 'Active',
  Completed: 'Completed',
  Rejected: 'Rejected',
  Closed: 'Closed'
}

export type ISTATUS =
  | "New"
  | "Approved"
  | "Active"
  | "Completed"
  | "Rejected"
  | "Closed";



export const APPROVAL_REJECT_OPTIONS = [{
  "label": "Approved",
  "value": "Approved"
}, {
  "label": "Rejected",
  "value": "Rejected"
}];


export const ENUM_APPROVE_REJECT = {
  "Approved": "Approved",
  "Rejected": "Rejected"
}


export const TRANSMISSION_CURRENT_STATUS_OPTIONS = [{
  "label": "In-Progress",
  "value": "In_Progress"
}, {
  "label": "Completed",
  "value": "Completed"
}];


export const LAND_OWNERSHIP_STATUS_OPTIONS = [{
  "label": "Freehold",
  "value": "Freehold"
}, {
  "label": "Leasehold",
  "value": "Leasehold"
}];

export const LAND_ACQUISITION_STATUS_OPTIONS = [{
  "label": "Completed",
  "value": "Completed"
}, {
  "label": "In-Progress",
  "value": "In_Progress"
},
{
  "label": "Pending",
  "value": "Pending"
}];



export const TYPE_OF_SOLAR_PLANT_OPTIONS = [{
  "label": "Ground-Mounted",
  "value": "Ground-Mounted"
}, {
  "label": "Rooftop",
  "value": "Rooftop"
}, {
  "label": "Floating",
  "value": "Floating"
}, {
  "label": "Hybrid",
  "value": "Hybrid"
}];

export const PROJECT_CONNECTION_TYPE_OPTIONS = [{
  "label": "On-Grid",
  "value": "On-Grid"
}, {
  "label": "Off-Grid",
  "value": "Off-Grid"
}, {
  "label": "Hybrid",
  "value": "Hybrid"
}];


export const PROJECT_PLANT_STATUS_OPTIONS = [{
  "label": "Under Development",
  "value": "Under Development"
}, {
  "label": "Operational",
  "value": "Operational"
}, {
  "label": "Commissioned",
  "value": "Commissioned"
}, {
  "label": "Completed",
  "value": "Completed"
}];


export const PLANT_STATUS_OPTIONS_2 = [{
  "label": "Under Maintenance",
  "value": "Under Maintenance"
}, {
  "label": "Operational",
  "value": "Operational"
}, {
  "label": "Partially Operational",
  "value": "Partially Operational"
},];

export const PROJECT_SOLAR_TECHNOLOGY_OPTIONS = [{
  "label": "Photovoltaic (PV)",
  "value": "Photovoltaic (PV)"
}, {
  "label": "Concentrated Solar Power (CSP)",
  "value": "Concentrated Solar Power (CSP)"
}, {
  "label": "Other",
  "value": "Other"
}];

export const PROJECT_PROJECT_OWNERSHIP_TYPE_OPTIONS = [{
  "label": "Developer",
  "value": "Developer"
}, {
  "label": "Owner",
  "value": "Owner"
}];

export const OWNERSHIP_STATUS_OPTIONS = [{
  "label": "Owned",
  "value": "Owned"
}, {
  "label": "Joint Ownership",
  "value": "Joint Ownership"
}, {
  "label": "Leased",
  "value": "Leased"
}];


export const PROJECT_SOLAR_MODULES_TYPE_OPTIONS = [{
  "label": "Monocrystalline",
  "value": "Monocrystalline"
}, {
  "label": "Polycrystalline",
  "value": "Polycrystalline"
}, {
  "label": "Thin Film",
  "value": "Thin Film"
}, {
  "label": "Other",
  "value": "Other"
}];

// New constructed projects field arrays
export const TYPE_OF_SOLAR_PLANT = [
  {
    label: "Ground-Mounted",
    value: "Ground-Mounted",
  },
  {
    label: "Rooftop",
    value: "Rooftop",
  },
  {
    label: "Floating",
    value: "Floating",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

export const CONNECTION_TYPE = [
  {
    label: "On-Grid",
    value: "On-Grid",
  },
  {
    label: "Off-Grid",
    value: "Off-Grid",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

export const PLANT_STATUS = [
  {
    label: "Under Development",
    value: "Under Development",
  },
  {
    label: "Operational",
    value: "Operational",
  },
  {
    label: "Commissioned",
    value: "Commissioned",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const SOLAR_TECHNOLOGY_USED = [
  {
    label: "Photovoltaic (PV)",
    value: "Photovoltaic (PV)",
  },
  {
    label: "Concentrated Solar Power (CSP)",
    value: "Concentrated Solar Power (CSP)",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const PROJECT_OWNERSHIP_TYPE = [
  {
    label: "Developer",
    value: "Developer",
  },
  {
    label: "Owner",
    value: "Owner",
  },
];


export const TYPE_OF_SOLAR_MODULES_USED = [
  {
    label: "Monocrystalline",
    value: "Monocrystalline",
  },
  {
    label: "Polycrystalline",
    value: "Polycrystalline",
  },
  {
    label: "Thin Film",
    value: "Thin Film",
  },
  {
    label: "Other",
    value: "Other",
  },
];


export const DOCUMENTS_UPLOAD = [
  {
    label: "Power Purchase Agreement",
    value: "Power Purchase Agreement",
  },
  {
    label: "Ownership Documents",
    value: "Ownership Documents",
  },
  {
    label: "Legal Clearance",
    value: "Legal Clearance",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const STATES_OPTIONS = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir"
].map((d: any) => ({ label: d, value: d }))


export const SPECIALIZATION_OPTIONS = [...EPC_CONTRACTORS_SPECIALIZATIONS, ...MANUFACTURERS_SPECIALIZATIONS, ...OTHER_SPECIALIZATIONS].map((d: any) => ({ label: d, value: d }))

export const IS_SUCCESS_RESPONSE = (result: any) => [200, 201].includes(result?.status || result?.code) && (result?.success ? result?.success : true)
