import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const DATA = [
    {
        "heading": "BUY PROJECT RIGHTS",
        "number": "01",
        "description": "Acquire full or partial project rights as per the need from the utility scale project or solar parks.",
        link: MAIN_ROUTES_CONST.project_rights_services,
    },
    {
        "heading": "BUY OPERATIONAL PROJECTS",
        "number": "02",
        "description": "Discover the perfect solar project for you, whether it's a rooftop installation or a fully commissioned solar projects.",
        link: MAIN_ROUTES_CONST.sales_existing_project_service,
    },
    {
        "heading": "BUY NEW CONSTRUCTION PROJECTS",
        "number": "03",
        "description": "Explore ongoing construction projects (rooftop installations or solar parks) from various developers.",
        link: MAIN_ROUTES_CONST.new_constructed_project_service,
    },
    {
        "heading": "LEASES ROOF AREAS",
        "number": "04",
        "description":   "Get rooftop space that fits your project requirements on lease or list your own rooftop to attract interested lessees.",
        link: MAIN_ROUTES_CONST.rooftop_solar_services,
    },
    {
        "heading": "OFFER LAND FOR UTILITY SCALE PROJECT",
        "number": "05",
        "description":  "Offer your land for lease or discover the perfect open space for a new solar park.",
        link: MAIN_ROUTES_CONST.land_lease_services,
    }

]