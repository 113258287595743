import { IMG_FEATURE_P2P_CROWDFUNDING, IMG_FEATURE_P2P_ENERGY_TRADING, IMG_FEATURE_P2P_MARKETPLACE } from "src/helpers";

export const CUROUSAL_DATA = [
    {
        image: IMG_FEATURE_P2P_ENERGY_TRADING,
        header: "P2P energy trading",
        sub_header: "Distributed Energy Resources (DER)",
        content: "• Monitoring  • Trading  • Control"
    },
    {
        image: IMG_FEATURE_P2P_CROWDFUNDING,
        header: "P2P crowdfunding",
        sub_header: "Build, own, operate and transfer the solar plant, to the owners after receipt of full payment in 7 years",
        content: ""
    },
    {
        image: IMG_FEATURE_P2P_MARKETPLACE,
        header: "Energy marketplace",
        sub_header: "Let’s know Your Green Energy Need  Our Platform will fulfil it 100%* !!!",
        content: ""
    }
]