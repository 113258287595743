export const DATA = [
  {
    "heading": "ROOFTOP SOLAR",
    "number": "01",
    "description": "Transform unused rooftop space into a clean energy powerhouse with our customized solar solutions, perfect for homes and businesses."
  },

  {
    "heading": "COMPETITIVE FINANCE",
    "number": "02",
    "description": "Flexible financing options to bring your solar projects to life, ensuring you have the resources to meet energy goals."
  },
  {
    "heading": "SOLAR EPC FOR SOCIETIES",
    "number": "03",
    "description": "Comprehensive Engineering, Procurement, and Construction (EPC) services for end-to-end solar project execution."
  },
]